// src/HomePage.js
import React, { useState } from 'react';
import axios from 'axios';
import './HomePage.css'; // Utilisez ce fichier pour les styles globaux
import triathlonImage from './assets/triathlon-swim.jpg';
import logo from './assets/sportpics.jpg'
import Form from './Form'; // Si vous voulez utiliser le Formulaire sur la même page

const HomePage = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL || '';

  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [allPhotosSelected, setAllPhotosSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Fonction pour réinitialiser les photos
  const resetPhotos = () => {
    setPhotos([]);
    setSelectedPhotos([]);
  };

  const handlePhotosLoaded = (photos) => {
    setPhotos(photos);
  };

  const handlePhotoClick = (photo) => {
    if (selectedPhotos.includes(photo)) {
      setSelectedPhotos(selectedPhotos.filter((p) => p.name !== photo.name));
      setAllPhotosSelected(false);
    } else {
      setSelectedPhotos([...selectedPhotos, photo]);
      setAllPhotosSelected(photos.length === selectedPhotos.length + 1);
    }
  };

  const isPhotoSelected = (photo) => selectedPhotos.some((p) => p.name === photo.name);

  const handleSelectAllClick = () => {
    if (allPhotosSelected) {
      setSelectedPhotos([]);
      setAllPhotosSelected(false);
    } else {
      setSelectedPhotos(photos);
      setAllPhotosSelected(true);
    }
  };

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/api/photos`, {
        photos: selectedPhotos.map((photo) => ({ url: photo.url, filename: photo.name })),
      }, {
        responseType: 'blob', // important
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'photos.zip');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Erreur lors du téléchargement des photos :', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="homepage-content">
      <div className="navbar">
        <div className="logo">
          <span><img src={logo} alt="SportPics" className="img-fluid"></img></span>
        </div>
        <div className="menu">
          <nav>
            <ul>
              <li>Nos Services</li>
              <li>Pricing</li>
            </ul>
          </nav>
        </div>
        <div className='empty'></div>
      </div>

      <div className="content">
        <div className="text-section">
          <h1>Retrouvez les photos de votre course</h1>
          <p>
            Découvrez et téléchargez facilement vos photos en saisissant
            votre numéro de dossard d'une course partenaire. Des images de qualité, des sportifs et
            sportives en action.
          </p>
          <Form apiUrl={apiUrl} onPhotosLoaded={handlePhotosLoaded} resetPhotos={resetPhotos}/>
          {/* <button className="btn btn-primary">Contactez-nous</button>
          <div className="testimonial">
            <div className="stars">⭐⭐⭐⭐⭐</div>
            <p>Clients heureux &nbsp; | &nbsp; Résultats étonnants</p>
            <blockquote>
              “Une recherche rapide et des photos magnifiques.”
            </blockquote>
            <p>Oliver Scott</p>
          </div> */}
        </div>
        <div className="image-section">
          <img src={triathlonImage} alt="Triathlon" className="img-fluid" />
        </div>
      </div>
      <div className="image-section">
        <div className="row">
          <div className="col-md-4 col-sm-6 mb-4" key='selectButton'>
            {photos.length > 0 && (
                <button type="button" className="btn btn-secondary mt-3" onClick={handleSelectAllClick} disabled={isLoading}>
                  {allPhotosSelected ? process.env.REACT_APP_UNSELECT_ALL : process.env.REACT_APP_SELECT_ALL}
                </button>
            )}
          </div>
          <div className="col-md-4 col-sm-6 mb-4" key='downloadButton'>
            {selectedPhotos.length > 0 && (
              <button type="button" className="btn btn-primary mt-3" onClick={handleDownload} disabled={isLoading}>
                {isLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    {process.env.REACT_APP_DOWNLOAD_IN_PROGRESS}
                  </>
                ) : (
                  <>
                    {selectedPhotos.length === 1 ? process.env.REACT_APP_DOWNLOAD_ONE : process.env.REACT_APP_DOWNLOAD}
                  </>
                )}
              </button>
            )}
          </div>
        </div>
        <div className="row">
          {photos.map((photo, id) => (
            <div className="col-md-4 col-sm-6 mb-4" key={id}>
              <img
                src={photo.url}
                alt={photo.name}
                className={`img-fluid ${isPhotoSelected(photo) ? 'selected' : ''}`}
                onClick={() => handlePhotoClick(photo)}/>
            </div>
          ))}
        </div>
      </div> 
    </div>
  );
};

export default HomePage;
