import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Form = ({ apiUrl, onPhotosLoaded, resetPhotos }) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [races, setRaces] = useState([]);
  const [selectedRace, setSelectedRace] = useState('');
  const [bibNumber, setBibNumber] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/events`);
        setEvents(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    if (selectedEvent) {
      const fetchRaces = async () => {
        try {
          const response = await axios.get(`${apiUrl}/api/races`, {
            params: {
              'event': selectedEvent,
            },
          });
          setRaces(response.data);
        } catch (error) {
          console.error('Erreur lors de la récupération des données :', error);
        }
      };

      fetchRaces();
    }
  }, [selectedEvent, apiUrl]);

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
    setSelectedRace('');
    resetPhotos();
  };

  const handleRaceChange = (event) => {
    setSelectedRace(event.target.value);
    resetPhotos();
  };

  const handleBibNumberChange = (event) => {
    setBibNumber(event.target.value);
  };

  const handleSubmit = async (e) => {
    setIsSearching(true);
    e.preventDefault();
    try {
      const response = await axios.get(`${apiUrl}/api/photos`, {
        params: {
          'event': selectedEvent,
          'race': selectedRace,
          'bib': bibNumber
        },
      });
      onPhotosLoaded(response.data);
    } catch (error) {
      console.error('Erreur lors de l\'envoi des données :', error);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <form>
      <div className="form-group">
        <label htmlFor="dropdownEvent">{ process.env.REACT_APP_EVENT }</label>
        <select id="dropdownEvent" className="form-control" value={selectedEvent} onChange={handleEventChange} >
          <option value="">{ process.env.REACT_APP_SELECT_EVENT }</option>
          {events.map((event, id) => (
            <option key={id} value={event.name}>
              {event.name}
            </option>
          ))}
        </select>
      </div>
      {selectedEvent && (
      <div className="form-group">
        <label htmlFor="dropdownRace">{ process.env.REACT_APP_RACE }</label>
        <select id="dropdownRace" className="form-control" value={selectedRace} onChange={handleRaceChange} >
          <option value="">{ process.env.REACT_APP_SELECT_RACE }</option>
          {races.map((race, id) => (
            <option key={id} value={race.name}>
              {race.name}
            </option>
          ))}
        </select>
      </div>
     )}
    {selectedEvent && selectedRace && (
    <div className="form-group">
      <label htmlFor="inputBibNumber">{ process.env.REACT_APP_BIB }</label>
      <input type="number" className="form-control" id="inputBibNumber" placeholder={ process.env.REACT_APP_BIB_NUMBER } value={bibNumber} onChange={handleBibNumberChange} />
      <button type="submit" className="btn btn-primary mt-3" onClick={handleSubmit} disabled={isSearching}>
        {isSearching ? (
          <>
            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
            {process.env.REACT_APP_SEARCH_IN_PROGRESS}
          </>
        ) : (
          <>
            {process.env.REACT_APP_SUBMIT}
          </>
        )}
      </button>
    </div>
    )}
  </form>
  );
};

export default Form;
