// src/App.js
import React from 'react';
import './App.css'; // Import du CSS existant
import HomePage from './HomePage';

const App = () => {

  return (
    <div className="App">
      <div className="App-body">
        <HomePage />
      </div>
    </div>
  );
};

export default App;