import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'production') {
  const script = document.createElement('script');
  script.setAttribute('defer', '');
  script.setAttribute('src','https://cloud.umami.is/script.js');
  script.setAttribute('data-website-id','928219bd-68c8-4515-9aca-330c7b006c5c');
  document.head.appendChild(script);
}

root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

reportWebVitals();
